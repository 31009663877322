import { Button } from 'components/Button';
import { Grid, GridItem } from 'components/Grid';
import { SanityImage } from 'components/SanityImage';
import { Typography } from 'components/Typography';
import { ContactCards as ContactCardType } from 'types/schema';

type Props = {
  node: ContactCardType;
};
export const ContactCards = ({ node }: Props) => (
  <Grid>
    {node.cards?.map((card) => (
      <GridItem className="md:col-span-2 lg:col-span-4" key={card._key}>
        <div className="rounded-xl bg-white h-full overflow-hidden">
          <div className="h-[230px] relative block overflow-hidden">
            {card.image && <SanityImage source={card.image} alt={card.name} />}
          </div>
          <div className="px-6 py-10">
            <Typography size="lg" as="h3" color="blue">
              {card.name}
            </Typography>
            <Typography className="mt-4" as="p">
              {card.position}
            </Typography>
            {card.email && (
              <a href={`mailto:${card.email}`} className="mt-16 block">
                <Button skin="outlined" aria-label={card.email}>
                  {card.email}
                </Button>
              </a>
            )}
          </div>
        </div>
      </GridItem>
    ))}
  </Grid>
);
