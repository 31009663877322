import clsx from 'clsx';
import ContentWrapper from 'components/ContentWrapper';
import SanityContent from 'components/SanityContent';
import { TextSection as TextSectionType } from 'types/schema';

type Props = {
  node: TextSectionType;
};

export const TextSection = (props: Props) => {
  const { simpBlock, align } = props.node;
  return (
    <ContentWrapper constrained>
      <div className={clsx(align, 'max-w-screen-md mx-auto')}>
        <SanityContent blocks={simpBlock} />
      </div>
    </ContentWrapper>
  );
};
