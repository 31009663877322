import { Icon, LandingTypography } from '@firi/ui-web';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import ContentWrapper from 'components/ContentWrapper';
import type { DownloadAppProps } from 'components/DownloadAppButton';
import { Grid, GridItem } from 'components/Grid';
import SanityContent from 'components/SanityContent';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { AppRatingSection as AppRatingSectionType } from 'types/schema';

const DownloadAppButtonNoSsr = dynamic<DownloadAppProps>(
  () => import('components/DownloadAppButton').then((mod) => mod.DownloadAppButton),
  {
    ssr: false,
  },
);

type Props = {
  node: AppRatingSectionType;
};
export const AppRatingSection = ({ node }: Props) => {
  const { simpBlock, horizontal, reverse, appleStoreRating, googlePlayRating } = node;

  return (
    <section
      className={clsx(
        'border-b border-firi-blue mt-12 pb-12 lg:pb-0',
        horizontal ? 'lg:mt-24 mt-12 pb-12 lg:pb-24' : 'mt-12 pb-12 lg:pb-0',
      )}
    >
      <ContentWrapper className={clsx(!horizontal ? 'overflow-hidden' : 'overflow-visible')}>
        <Grid className="gap-8 lg:gap-20">
          <GridItem
            className={clsx(
              'justify-center',
              reverse ? 'lg:order-last lg:justify-end' : '',
              horizontal ? 'lg:col-span-6' : 'flex lg:col-span-7',
            )}
          >
            <div
              className={clsx(
                `rounded-full overflow-hidden flex flex-col bg-blue items-center h-[355px] lg:h-[500px]`,
                reverse ? 'float-left justify-start' : 'float-none lg:float-right',
                horizontal ? `w-auto lg:w-[905px]` : 'lg:translate-y-6',
                horizontal && '-mx-20 md:mx-0',
              )}
            >
              <div className="flex-col flex flex-1 relative mt-14">
                <div className="text-center">
                  <LandingTypography color="white" size="lg">
                    <Trans>Download the Firi-app!</Trans>
                  </LandingTypography>
                </div>
                <div className="flex mt-8">
                  <div className="flex flex-col">
                    <LandingTypography color="white" size="xl">
                      {appleStoreRating}
                    </LandingTypography>
                    <div className="flex flex-row my-2">
                      {appleStoreRating &&
                        Array.from({ length: Math.floor(appleStoreRating) }).map((_, index) => (
                          <Icon
                            key={index}
                            icon="star"
                            className="mr-2 fill-[#FBDD3E] text-[#FBDD3E]"
                          />
                        ))}
                      {appleStoreRating && appleStoreRating % 1 > 0.7 ? (
                        <Icon icon="star" className="mr-2 fill-[#FBDD3E] text-[#FBDD3E]" />
                      ) : (
                        <Icon icon="half-star" />
                      )}
                    </div>
                    <LandingTypography color="white" size="xs">
                      <Trans>Rating on App Store</Trans>
                    </LandingTypography>
                  </div>
                  <div className="flex flex-col ml-11">
                    <LandingTypography color="white" size="xl">
                      {googlePlayRating}
                    </LandingTypography>
                    <div className="flex flex-row my-2 ">
                      {googlePlayRating &&
                        Array.from({ length: Math.floor(googlePlayRating) }).map((_, index) => (
                          <Icon
                            key={index}
                            icon="star"
                            className="mr-2 fill-[#FBDD3E] text-[#FBDD3E]"
                          />
                        ))}
                      {googlePlayRating && googlePlayRating % 1 > 0.7 ? (
                        <Icon icon="star" className="mr-2 fill-[#FBDD3E] text-[#FBDD3E]" />
                      ) : (
                        <Icon icon="half-star" />
                      )}
                    </div>
                    <LandingTypography color="white" size="xs">
                      <Trans>Rating on Google Play</Trans>
                    </LandingTypography>
                  </div>
                </div>
                <div className="flex absolute bottom-0 none hidden lg:flex">
                  <div className="absolute top-24 z-20 left-0 right-0 text-center">
                    <LandingTypography size="xs">
                      <Trans>Scan the QR code</Trans>
                    </LandingTypography>
                  </div>
                  <Image
                    src={'/images/firi/qr-code-phone.png'}
                    alt={'QR-code for download'}
                    width={300}
                    height={300}
                  />
                </div>
                <div className="flex justify-center mb-8 mt-10">
                  <DownloadAppButtonNoSsr text={t`Download here`} skin="white-primary" />
                </div>
              </div>
            </div>
          </GridItem>
          <GridItem
            className={clsx(
              'flex flex-col justify-center',
              horizontal ? 'lg:col-span-6' : 'lg:col-span-5',
            )}
          >
            <SanityContent blocks={simpBlock} />
          </GridItem>
        </Grid>
      </ContentWrapper>
    </section>
  );
};
