import { Card, WebTypography } from '@firi/ui-web';
import { Trans } from '@lingui/macro';
import clsx from 'clsx';
import { getIconBySanityKey } from 'helpers/icon';
import { getLocalizedUrl } from 'helpers/url';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { ExternalArticlesSection } from 'types/schema';

import { ExternalArticlePost } from './ExternalArticlePost';
import { HorizontalScroller } from './HorizontalScroller';

export const BlueSectionWithOverflowScroller = ({
  title,
  svgType,
  externalArticles,
}: Omit<ExternalArticlesSection, '_type'>) => {
  const Illustration = getIconBySanityKey(svgType);
  const router = useRouter();

  return (
    <div>
      <div className="relative pt-28 mb-4 lg:pt-0 lg:grid grid-cols-12 lg:-ml-56">
        <div className="bg-firi-blue absolute top-[-5%] z-[-1] -ml-4 h-[200px] rounded-r-full py-10 px-4 lg:rounded-full lg:h-[600px] lg:w-[905px] lg:relative col-span-5">
          <div className="flex justify-between lg:flex-col lg:h-full lg:items-center lg:justify-center">
            <WebTypography color="white" size="xl" className="w-1/2">
              {title}
            </WebTypography>
            <div className="w-[104px] h-[104px] absolute -right-4 top-0 lg:relative lg:w-[240px] lg:h-[240px] ">
              <Illustration />
            </div>
          </div>
        </div>
        <div className="flex items-center col-span-7">
          <HorizontalScroller className="lg:grid gap-4 grid-cols-12">
            {externalArticles?.slice(0, 2)?.map((card, index) => {
              return (
                <div
                  className={clsx(
                    'min-w-[300px] mr-4 first:ml-4 flex flex-col justify-between lg:col-span-6 lg:min-w-min',
                  )}
                  key={index}
                >
                  <ExternalArticlePost {...card} />
                </div>
              );
            })}
          </HorizontalScroller>
        </div>
      </div>
      <div className="text-right">
        <Link
          href={getLocalizedUrl('/press/in-media', router.locale, router.query)}
          locale={router.locale}
        >
          <a>
            <WebTypography size="md" color="blue">
              <Trans>See all articles</Trans>
            </WebTypography>
          </a>
        </Link>
      </div>
    </div>
  );
};
