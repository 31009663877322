import { firiMerge } from '@firi/shared/styles';
import ContentWrapper from 'components/ContentWrapper';
import { Grid, GridItem } from 'components/Grid';
import SanityContent from 'components/SanityContent';
import { YoutubeVideoSection as YoutubeVideoSectionType } from 'types/schema';

type Props = {
  node: YoutubeVideoSectionType;
};
export const YoutubeVideoSection = ({ node }: Props) => {
  return (
    <section className="md:mt-16 mt-12 pb-12 md:pb-24 border-b border-firi-blue">
      <ContentWrapper>
        <Grid>
          {node.simpBlock ? (
            <GridItem className="lg:col-span-6 flex flex-col justify-center">
              <SanityContent blocks={node.simpBlock} />
            </GridItem>
          ) : null}
          <GridItem className={firiMerge(node.simpBlock ? 'lg:col-span-6' : '')}>
            {node.youtubeId && (
              <iframe
                style={{
                  aspectRatio: 16 / 9,
                }}
                width="100%"
                src={`https://www.youtube.com/embed/${node.youtubeId}`}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="rounded-md"
              />
            )}
          </GridItem>
        </Grid>
      </ContentWrapper>
    </section>
  );
};
