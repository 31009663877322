import { Card, Icon } from '@firi/ui-web';
import ContentWrapper from 'components/ContentWrapper';
import { Typography } from 'components/Typography';
import { AppReviewsSection as AppReviewsSectionType } from 'types/schema';

type Props = {
  node: AppReviewsSectionType;
};
export const AppReviewsSection = ({ node }: Props) => {
  const { appReviews } = node;

  return (
    <section className="py-20 border-b border-firi-blue">
      <ContentWrapper constrained className="flex flex-col lg:flex-row gap-6">
        {appReviews?.map((review) => (
          <Card className="bg-blue-100 flex-col flex flex-1 justify-between p-6">
            <Typography>{review.review}</Typography>
            <div>
              <div className="flex mt-6 ">
                {review.rating &&
                  Array.from({ length: review.rating }).map((_, index) => (
                    <Icon key={index} icon="star" className="mr-2" color="blue" size="sm" />
                  ))}
              </div>
              <Typography size="xs" color="2" className="mt-2">
                {review.store}
              </Typography>
            </div>
          </Card>
        ))}
      </ContentWrapper>
    </section>
  );
};
