import { Button } from 'components/Button';
import { useSanityLink } from 'hooks/useSanityLink';
import Link from 'next/link';
import { Cta as CtaType } from 'types/schema';

type Props = {
  node: CtaType;
};
export const Cta = ({ node }: Props) => {
  const { ctaText, href } = node;
  const linkProps = href && useSanityLink(href);

  if (!linkProps) return null;

  return (
    <Link {...linkProps}>
      <a className="inline-block">
        <Button skin="outlined" aria-label={ctaText as string} eventAction="CTA">
          {ctaText}
        </Button>
      </a>
    </Link>
  );
};
