import { useCryptoCurrencies, useStakingAssets } from '@firi/shared';
import { CryptoCurrency } from '@firi/shared/gql/gqlTypes';
import { Card, Icon, WebTypography } from '@firi/ui-web';
import { t, Trans } from '@lingui/macro';
import { Typography } from 'components/Typography';
import { StakingRewardSection as StakingRewardSectionType } from 'types/schema';

const comingSoonStakingTokens: Array<{ currency: CryptoCurrency; label: string }> = [
  { currency: CryptoCurrency.Ada, label: t`Right around the corner` },
];

type Props = {
  node: StakingRewardSectionType;
};

export const StakingRewardSection = ({ node }: Props) => {
  const { data } = useStakingAssets();
  const { getName } = useCryptoCurrencies();

  return (
    <section>
      <Card className="mb-4 p-6 md:p-20 lg:mb-10 overflow-hidden flex flex-col">
        <Typography size="xl" color="blue" weight="medium">
          {node.title}
        </Typography>
        <div className="flex flex-col border rounded-xl border-black-150 mt-10 w-auto md:w-max">
          {data?.staking.currencies?.map((c) => (
            <div className="flex flex-row">
              <div className="border-r border-black-150 p-6 flex min-w-[170px] items-center">
                <Icon icon={c.currency} size="lg" />
                <WebTypography className="ml-2">{c.name}</WebTypography>
              </div>
              <div className="p-6">
                <WebTypography color="positive" size="md">
                  {c.apy}%
                </WebTypography>{' '}
                <WebTypography color="3">
                  <Trans>expected yearly earn</Trans>
                </WebTypography>
              </div>
            </div>
          ))}
          {data?.staking?.currencies?.find((c) => c.currency === CryptoCurrency.Ada)
            ? null
            : comingSoonStakingTokens
                .filter((soonStaking) =>
                  data?.staking.currencies?.some((c) => c.currency !== soonStaking.currency),
                )
                .map((soonStaking) => (
                  <div className="flex flex-row">
                    <div className="border-r border-black-150 p-6 flex min-w-[170px] items-center ">
                      <Icon icon={soonStaking.currency} size="lg" />
                      <WebTypography className="ml-2">
                        {getName(soonStaking.currency)}
                      </WebTypography>
                    </div>
                    <div className="p-6">
                      <i>
                        <Trans id={soonStaking.label} />
                      </i>
                    </div>
                  </div>
                ))}
        </div>
        <div className="p-2 pt-4">
          <WebTypography size="sm">
            <i>
              <Trans>Expected annual yield is an estimate and will vary over time.</Trans>
            </i>
          </WebTypography>
        </div>
      </Card>
    </section>
  );
};
