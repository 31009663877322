import clsx from 'clsx';
import { urlFor } from 'helpers/image';
import Image from 'next/image';
import { CtaSection } from 'types/schema';

import { Grid, GridItem } from './Grid';
import SanityContent from './SanityContent';

export const CTASection = ({
  image,
  imageAlt,
  simpBlock,
  horizontal,
  reverse,
  circle,
}: Omit<CtaSection, '_type'>) => {
  let imgWidth = 650;
  let imgHeight = 1000;

  if (horizontal) {
    imgWidth = 900;
    imgHeight = 500;
  }

  if (circle) {
    imgWidth = 500;
    imgHeight = 500;
  }

  return (
    <Grid className="gap-8 lg:gap-20">
      <GridItem
        className={clsx(
          'justify-center',
          reverse ? 'lg:order-last lg:justify-end' : '',
          horizontal ? 'lg:col-span-6' : 'flex lg:col-span-7',
        )}
      >
        <div
          className={clsx(
            'rounded-full overflow-hidden flex flex-col',
            reverse ? 'float-left justify-start' : 'float-right',
            horizontal ? 'lg:w-[905px]' : !circle && 'lg:translate-y-6',
            horizontal && '-mx-12 md:mx-0',
          )}
        >
          <Image
            src={urlFor(image).width(imgWidth).height(imgHeight).url()}
            alt={imageAlt}
            width={imgWidth}
            height={imgHeight}
          />
        </div>
      </GridItem>
      <GridItem
        className={clsx(
          'flex flex-col justify-center',
          horizontal ? 'lg:col-span-6' : 'lg:col-span-5',
        )}
      >
        <SanityContent blocks={simpBlock} />
      </GridItem>
    </Grid>
  );
};
