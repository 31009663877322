import { AppDownloadProps } from '@firi/ui-web';
import BlockContent from '@sanity/block-content-to-react';
// Typescript find the types, but eslint can't find it
// eslint-disable-next-line
import { Reference } from '@sanity/types/dist/dts';
import clsx, { ClassValue } from 'clsx';
import { useSanityLink } from 'hooks/useSanityLink';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import React from 'react';
import { SanityTable } from 'types/sanity';
import { AppDownloadButton } from 'types/schema';

import { AppRatingSection } from './SanityContents/AppRatingSection';
import { AppReviewsSection } from './SanityContents/AppReviewsSection';
import { AppScreenSection } from './SanityContents/AppScreenSection';
import { ChristmasSection } from './SanityContents/ChristmasSection';
import { ContactCards } from './SanityContents/ContactCards';
import { CryptoCurrencySection } from './SanityContents/CryptoCurrencySection';
import { Cta } from './SanityContents/Cta';
import { CtaButton } from './SanityContents/CtaButton';
import { CtaSection } from './SanityContents/CtaSection';
import { DanishLandingHero } from './SanityContents/DanishLandingHero';
import { ExternalArticlesSection } from './SanityContents/ExternalArticlesSection';
import { FaqCategory } from './SanityContents/FaqCategory';
import { GetStartedCardSection } from './SanityContents/GetStartedCardSection';
import { LandingHeroV2 } from './SanityContents/LandingHeroV2';
import { SectionCustom } from './SanityContents/SectionCustom';
import { StakingRewardSection } from './SanityContents/StakingRewardSection';
import { TextSection } from './SanityContents/TextSection';
import { YoutubeVideoSection } from './SanityContents/YoutubeVideoSection';
import { SanityImage } from './SanityImage';
import { Section } from './Section';
import { Typography } from './Typography';

const AppDownloadAnchorNoSsr = dynamic<Omit<AppDownloadProps, 'locale'>>(
  () => import('components/AppDownloadAnchor').then((mod) => mod.AppDownloadAnchor),
  {
    ssr: false,
  },
);

const CookieToggle = dynamic(() => import('./CookieToggle').then((mod) => mod.CookieToggle), {
  ssr: false,
});

type SanityContentProps = {
  blocks: any;
  addClass?: boolean;
  className?: ClassValue;
};

const serializers = () => ({
  types: {
    table: (props: { node: SanityTable }) => {
      const [tableHead, ...rows] = props.node.rows;

      return (
        <table>
          <thead>
            <tr>
              {tableHead.cells.map((th) => (
                <th key={th}>{th}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row) => (
              <tr key={row._key}>
                {row.cells.map((td) => (
                  <td key={td}>{td}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      );
    },
    block: (props: { children: any; node: any; options: any }) => {
      const style: string = props.node.style || 'normal';

      const styles: Record<string, React.ReactElement> = {
        h1: (
          <section className="mb-6 overflow-hidden" id={props.node._key}>
            <Typography as="h1" size="2xl" color="blue">
              {props.children}
            </Typography>
          </section>
        ),
        h2: (
          <section className="mb-4 overflow-hidden" id={props.node._key}>
            <Typography as="h2" size="xl">
              {props.children}
            </Typography>
          </section>
        ),
        h3: (
          <section className="mb-4 overflow-hidden" id={props.node._key}>
            <Typography as="h3" size="lg">
              {props.children}
            </Typography>
          </section>
        ),
        h4: (
          <section className="mb-4 overflow-hidden" id={props.node._key}>
            <Typography as="h4">{props.children}</Typography>
          </section>
        ),
        h5: (
          <section className="mb-4 overflow-hidden" id={props.node._key}>
            <Typography as="h5">{props.children}</Typography>
          </section>
        ),
        h6: (
          <section className="mb-4 overflow-hidden" id={props.node._key}>
            <Typography as="h6">{props.children}</Typography>
          </section>
        ),
        blockquote: (
          <section className="mb-6 overflow-hidden" id={props.node._key}>
            <Typography as="i" className="pl-2">
              "{props.children}"
            </Typography>
          </section>
        ),
        caption: (
          <section className="mb-6 overflow-hidden" id={props.node._key}>
            <Typography color="2" size="sm">
              {props.children}
            </Typography>
          </section>
        ),
        subtitle1: (
          <section className="mb-6 overflow-hidden" id={props.node._key}>
            <Typography size="sm">{props.children}</Typography>
          </section>
        ),
        normal: (
          <section className="mb-4 overflow-hidden" id={props.node._key}>
            <Typography as="p">{props.children}</Typography>
          </section>
        ),
      };

      return styles[style];
    },
    image: (props: { node: { _type: 'image'; asset: Reference; alt?: string } }) => (
      <SanityImage source={props.node} alt={props.node.alt ?? ''} />
    ),
    imageV2: (props: { node: { _type: 'image'; asset: Reference; alt?: string } }) => (
      <SanityImage source={props.node} alt={props.node.alt ?? ''} />
    ),
    imageElement: (props: { node: { _type: 'image'; asset: Reference; alt?: string } }) => (
      <SanityImage source={props.node} alt={props.node.alt ?? ''} />
    ),
    section: (props: any) => {
      return (
        <Section
          title={props.node.title}
          _key={props.node._key}
          highlighted={props.node?.highlighted}
          showToggle={props.node?.showToggle}
          constrained={props.node?.constrained}
          image={props.node?.imageV2 || props.node?.image}
        >
          <SanityContent blocks={props.node.sectionContent} />
        </Section>
      );
    },
    appDownloadButton: ({ node }: { node: AppDownloadButton }) => {
      return (
        <div className="inline-block mr-4 mb-2">
          {node.platform && <AppDownloadAnchorNoSsr type={node.platform} color={node.color} />}
        </div>
      );
    },
    appRatingSection: AppRatingSection,
    appReviewsSection: AppReviewsSection,
    appScreenSection: AppScreenSection,
    ctaButton: CtaButton,
    cookieToggle: CookieToggle,
    christmasSection: ChristmasSection,

    faqCategory: FaqCategory,
    ctaSection: CtaSection,
    getStartedCardSection: GetStartedCardSection,
    cta: Cta,
    sectionCustom: SectionCustom,
    contactCards: ContactCards,
    cryptoCurrencySection: CryptoCurrencySection,
    textSection: TextSection,
    externalArticlesSection: ExternalArticlesSection,
    landingHeroV2: LandingHeroV2,
    danishLandingHero: DanishLandingHero,
    youtubeVideoSection: YoutubeVideoSection,
    stakingRewardSection: StakingRewardSection,
  },

  list: (props: { children: any; type: 'number' | 'bullet' }) => {
    if (props.type === 'number') {
      return <ol className="mb-6">{props.children}</ol>;
    }
    return <ul className="mb-6">{props.children}</ul>;
  },

  marks: {
    link: (props: { mark: { href: string; _type: 'link' }; children: any }) => {
      if (!props.mark.href) return null;

      const linkProps = useSanityLink(props.mark.href);

      return (
        <Link {...linkProps}>
          <a>{props.children}</a>
        </Link>
      );
    },
  },
});

const SanityContent = ({
  blocks,
  addClass = true,
  className,
  variant,
}: SanityContentProps & { variant?: string }) => (
  <BlockContent
    blocks={blocks}
    renderContainerOnSingleChild
    className={addClass ? clsx('cms', className) : ''}
    serializers={serializers()}
  />
);

export default SanityContent;
