import { Icon, IconName } from '@firi/ui-web';
import clsx from 'clsx';
import { Button } from 'components/Button';
import { useSanityLink } from 'hooks/useSanityLink';
import Link from 'next/link';
import { CtaButton as CtaButtonType } from 'types/schema';

type Props = {
  node: CtaButtonType;
};

export const CtaButton = ({ node }: Props) => {
  const linkProps = useSanityLink(node?.href || '');

  return (
    <div className="inline-block lg:mr-10 mb-4">
      <Link {...linkProps}>
        <a>
          <Button
            skin={node?.skin || 'primary'}
            aria-label={node.buttonText || ''}
            eventAction="CTA"
            className={clsx(node.customBgColor && `bg-[${node.customBgColor}]`)}
          >
            <div
              className={clsx('flex items-center space-x-2', {
                'flex-row-reverse': node.icon && node.iconPosition === 'right',
              })}
            >
              {node.icon && (
                <Icon
                  icon={node.icon as IconName}
                  size={node.iconSize || '2xl'}
                  color="white"
                  className={clsx(node.iconPosition === 'right' ? 'ml-2' : 'mr-2')}
                />
              )}
              <span>{node.buttonText}</span>
            </div>
          </Button>
        </a>
      </Link>
    </div>
  );
};
