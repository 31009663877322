import { Accordion, Card } from '@firi/ui-web';
import SanityContent from 'components/SanityContent';
import { Section } from 'components/Section';
import { Typography } from 'components/Typography';
import { FaqCategory as FaqCategoryType } from 'types/schema';

type Props = {
  node: FaqCategoryType;
};

export const FaqCategory = (props: Props) => {
  const { faqCollectionList = [], title = '', subtitle = '', _key = '' } = props.node;

  return (
    <Section title={title} subtitle={subtitle} _key={_key} showToggle>
      {faqCollectionList?.map((item) => {
        const { faqList = [] } = item;
        return (
          <div key={item._key}>
            {faqList?.map((faqItem: any) => {
              const { title, body } = faqItem;
              return (
                <Card key={title} border className="mb-8" as="article">
                  <Accordion
                    title={
                      <div className="mb-2 ">
                        <Typography as="p" size="md">
                          {title}
                        </Typography>
                      </div>
                    }
                    as="div"
                  >
                    <SanityContent blocks={body} />
                  </Accordion>
                </Card>
              );
            })}
          </div>
        );
      })}
    </Section>
  );
};
