import React from 'react';

const SnowFlakes = () => {
  return (
    <>
      <div className="snowflake">❅</div>
      <div className="snowflake">❆</div>
      <div className="snowflake">❅</div>
      <div className="snowflake">❆</div>
      <div className="snowflake">❅</div>
      <div className="snowflake">❆</div>
      <div className="snowflake">❅</div>
      <div className="snowflake">❆</div>
      <div className="snowflake">❅</div>
      <div className="snowflake">❆</div>
      <div className="snowflake">❅</div>
      <div className="snowflake">❆</div>
      <div className="snowflake">❆</div>
      <div className="snowflake">❆</div>
      <div className="snowflake">❆</div>
      <div className="snowflake">❆</div>
    </>
  );
};

export default SnowFlakes;
