import { Card, WebTypography } from '@firi/ui-web';
import { Trans } from '@lingui/macro';
import { ExternalArticle, Source } from 'types/schema';

import { Button } from './Button';

export type ExternalArticleItem = Omit<ExternalArticle, 'source'> & { source: Source };

export const ExternalArticlePost = ({
  title,
  blogPitch,
  href,
  paywall,
  source,
}: ExternalArticleItem) => {
  return (
    <Card className="flex flex-col justify-between max-w-[370px] flex-grow">
      <div className="flex flex-col">
        <WebTypography size="md" color="blue" className="mb-6">
          {title}
        </WebTypography>
        <WebTypography size="sm" className="mb-8 line-clamp-4">
          {blogPitch}
        </WebTypography>
      </div>
      <a href={href}>
        <div className="mb-4">
          <Button skin="outlined" aria-label="read more">
            <Trans>Read more</Trans>
          </Button>
        </div>
      </a>
      {paywall && (
        <WebTypography size="xs" color="2">
          <Trans>Requires subscription at</Trans> {source.source}
        </WebTypography>
      )}
    </Card>
  );
};
