import clsx from 'clsx';
import ContentWrapper from 'components/ContentWrapper';
import { CTASection } from 'components/CTASection';
import { CtaSection as CtaSectionType } from 'types/schema';

type Props = {
  node: CtaSectionType;
};
export const CtaSection = ({ node }: Props) => {
  const { image, imageAlt, simpBlock, horizontal, reverse, circle, christmas } = node;

  return (
    <section
      className={clsx(
        'border-b border-firi-blue',
        horizontal || circle ? 'lg:mt-24 mt-12 pb-12 lg:pb-24' : 'mt-12 pb-12 lg:pb-0',
      )}
    >
      <ContentWrapper className={clsx(!horizontal ? 'overflow-hidden' : 'overflow-visible')}>
        <CTASection
          image={image}
          imageAlt={imageAlt}
          simpBlock={simpBlock}
          horizontal={horizontal}
          reverse={reverse}
          circle={circle}
          christmas={christmas}
        />
      </ContentWrapper>
    </section>
  );
};
