import { getHeightByRatio, getSanityImageDimensions, urlFor } from 'helpers/image';
import Image, { ImageProps } from 'next/image';
import { SanityImageAssets } from 'types/sanity';

export const SanityImage = ({
  source,
  alt,
  ...imageProps
}: { source: SanityImageAssets; alt: string } & Omit<ImageProps, 'src' | 'width' | 'height'>) => {
  const { width, aspectRatio } = getSanityImageDimensions(source);

  const imageUrl = urlFor(source);

  const maxWidth = width > 920 ? 920 : width;

  return (
    <Image
      src={imageUrl.url()}
      width={maxWidth}
      height={getHeightByRatio(maxWidth, aspectRatio)}
      alt={alt}
      {...imageProps}
    />
  );
};
