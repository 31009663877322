import clsx from 'clsx';
import { ReactNode } from 'react';

interface HorizontalScrollerProps {
  children: ReactNode;
  className?: any;
}

export const HorizontalScroller = ({ children, className }: HorizontalScrollerProps) => {
  return (
    <div className={clsx('no-scrollbar flex overflow-scroll -ml-4 md:pl-4', className)}>
      {children}
    </div>
  );
};
