import MitIdBtn from '@firi/shared/auth/assets/mit-id-btn.svg';
import MitIdBtnWhite from '@firi/shared/auth/assets/mit-id-btn-white.svg';
import { ComponentPropsWithoutRef } from 'react';

export const MitIdLoginButton = (
  props: ComponentPropsWithoutRef<'button'> & { variant?: 'blue' | 'white' },
) => (
  <button {...props} className="overflow-hidden">
    {props.variant === 'white' ? (
      <MitIdBtnWhite alt="Login with MitId" height="42" />
    ) : (
      <MitIdBtn alt="Login with MitId" height="42" />
    )}
  </button>
);
