import { Card, Icon, IconName } from '@firi/ui-web';
import { getIconBySanityKey } from 'helpers/icon';
import { SvgType } from 'types/schema';

import { Typography } from './Typography';

interface BlueNumberedCardProps {
  number: number;
  title: string;
  text: string;
  icon?: IconName;
  svgType?: SvgType;
}

export const BlueNumberedCard = ({ number, title, text, icon, svgType }: BlueNumberedCardProps) => {
  const Illustration = getIconBySanityKey(svgType);
  return (
    <Card className="!bg-firi-blue text-white lg:p-6 lg:pb-10 h-full flex flex-col">
      <div className="bg-white w-10 h-10 rounded-full flex justify-center items-center ">
        <Typography color="blue" size="md">
          {number}
        </Typography>
      </div>
      <Typography as="h3" size="lg" color="white" className="mt-5">
        {title}
      </Typography>
      <Typography as="p" size="md" className="mt-3" color="white">
        {text}
      </Typography>
      {icon && (
        <div className="flex-1 flex justify-center items-end mt-9 px-4 mb-4">
          <Icon icon={icon} size="3xl" color="white" />
        </div>
      )}
      {svgType && Illustration && (
        <div
          className={`flex-1 flex justify-end mt-6 px-4 items-end ${
            svgType === 'mit-id' ? 'w-[200px] ml-auto mr-auto mt-20 lg:mt-12' : 'w-[180px] ml-auto'
          }`}
        >
          <Illustration className="h-full" />
        </div>
      )}
    </Card>
  );
};
