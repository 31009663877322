import { getPlatformLink } from '@firi/shared';
import { Trans } from '@lingui/macro';
import { BlueNumberedCard } from 'components/BlueNumberedCard';
import ContentWrapper from 'components/ContentWrapper';
import { GridItem } from 'components/Grid';
import { HorizontalScroller } from 'components/HorizontalScroller';
import { Typography } from 'components/Typography';
import { GetStartedCardSection as GetStartedCardSectionType } from 'types/schema';

type Props = {
  node: GetStartedCardSectionType;
};
export const GetStartedCardSection = ({ node }: Props) => {
  const cards = node.cards;

  return (
    <section className="lg:my-24 my-12 pb-12 lg:pb-24 border-b border-firi-blue">
      <ContentWrapper constrained>
        <div className="flex items-center mb-8">
          <div className="mr-4">
            <Typography as="h2" size="xl">
              {node.header || <Trans>Get started</Trans>}
            </Typography>
          </div>
        </div>
        {cards && (
          <HorizontalScroller className="lg:grid lg:gap-4 grid-cols-12">
            {cards.map((card, index) => {
              return (
                <GridItem
                  key={card._key}
                  className="md:col-span-2 lg:col-span-4 min-w-[300px] mr-2 first:ml-4 md:first:ml-0"
                >
                  <a href={getPlatformLink({ link: 'signup' })}>
                    <BlueNumberedCard
                      number={index + 1}
                      title={card.header || ''}
                      text={card.body || ''}
                      icon={card.icons}
                      svgType={card.svgType}
                    />
                  </a>
                </GridItem>
              );
            })}
          </HorizontalScroller>
        )}
      </ContentWrapper>
    </section>
  );
};
