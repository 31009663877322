import clsx from 'clsx';
import { BlueSectionWithOverflowScroller } from 'components/BlueSectionWithOverflowScroller';
import ContentWrapper from 'components/ContentWrapper';
import { ExternalArticlesSection as ExternalArticlesSectionType } from 'types/schema';

type Props = {
  node: ExternalArticlesSectionType;
};

export const ExternalArticlesSection = ({ node }: Props) => {
  return (
    <section className={clsx('border-b border-firi-blue lg:mt-24 mt-12 pb-12 lg:pb-24')}>
      <ContentWrapper className="overflow-visible">
        <BlueSectionWithOverflowScroller
          title={node.title}
          svgType={node.svgType}
          externalArticles={node.externalArticles}
        />
      </ContentWrapper>
    </section>
  );
};
