import { transformSanityUrl } from 'helpers/url';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

export const useSanityLink = (href: string) => {
  const router = useRouter();
  const url = transformSanityUrl(href);

  const props = useMemo(() => {
    return {
      href: url,
      locale: router?.locale,
    };
  }, [router, url]);

  return props;
};
