import clsx from 'clsx';
import ContentWrapper from 'components/ContentWrapper';
import { Grid, GridItem } from 'components/Grid';
import SanityContent from 'components/SanityContent';
import { urlFor } from 'helpers/image';
import Image from 'next/image';
import { AppScreenSection as AppScreenSectionType } from 'types/schema';

type Props = {
  node: AppScreenSectionType;
};
export const AppScreenSection = ({ node }: Props) => {
  const { simpBlock, reverse, image, imageAlt } = node;

  return (
    <section className={clsx('border-b border-firi-blue mt-12 lg:mt-24 pb-12 lg:pb-24')}>
      <ContentWrapper className={'overflow-visible'}>
        <Grid className="gap-8 lg:gap-20">
          <GridItem
            className={clsx(
              'justify-center lg:col-span-6',
              reverse ? 'lg:order-last lg:justify-end' : '',
            )}
          >
            <div
              className={clsx(
                'flex justify-center rounded-full overflow-hidden bg-blue h-[265px] lg:h-[500px] w-auto lg:w-[905px] -mx-12 md:mx-0 relative items-center',
                reverse ? '' : 'float-none lg:float-right',
              )}
            >
              <div className="w-[200px] lg:w-[350px] lg:h-[500px]">
                {image && (
                  <Image src={urlFor(image).url()} alt={imageAlt} width={350} height={500} />
                )}
              </div>
            </div>
          </GridItem>
          <GridItem className={clsx('flex flex-col justify-center lg:col-span-6')}>
            <SanityContent blocks={simpBlock} />
          </GridItem>
        </Grid>
      </ContentWrapper>
    </section>
  );
};
