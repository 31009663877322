import ChristmasSanta from '@firi/shared/assets/illustrations/christmas-santa.svg';
import { Trans } from '@lingui/macro';
import clsx from 'clsx';
import { Button } from 'components/Button';
import ContentWrapper from 'components/ContentWrapper';
import { Grid, GridItem } from 'components/Grid';
import SanityContent from 'components/SanityContent';
import { Typography } from 'components/Typography';
import { urlFor } from 'helpers/image';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { ChristmasSection as ChristmasSectionType } from 'types/schema';

import SnowFlakes from '../SnowFlakes';

type Props = {
  node: ChristmasSectionType;
};
export const ChristmasSection = ({ node }: Props) => {
  const { simpBlock, reverse, image } = node;
  const router = useRouter();

  return (
    <section className={clsx('border-b border-firi-blue mt-12 lg:mt-24 pb-12 lg:pb-24 relative')}>
      <div className="relative lg:absolute right-auto top-auto lg:right-8 lg:-top-16 flex justify-center">
        <ChristmasSanta height={120} width={150} />
      </div>
      <ContentWrapper className="overflow-visible">
        <Grid className="gap-8 lg:gap-20">
          <GridItem
            className={clsx(
              'justify-center lg:col-span-6',
              reverse ? 'lg:order-last lg:justify-end' : '',
            )}
          >
            <div
              className={clsx(
                'hidden lg:flex relative justify-center rounded-full overflow-hidden bg-black-800 h-[265px] lg:h-[500px] w-auto lg:w-[905px] -mx-12 md:mx-0 items-center',
                reverse ? '' : 'float-none lg:float-right',
              )}
            >
              <SnowFlakes />

              <div
                className="w-[250px] lg:w-[500px] lg:h-[500px] lg:ml-24"
                style={{ zIndex: 10000 }}
              >
                {image && (
                  <Image
                    src={urlFor(image).url()}
                    alt={'christmas phone'}
                    width={500}
                    height={500}
                  />
                )}
              </div>
            </div>
          </GridItem>
          <GridItem className={clsx('flex flex-col justify-center lg:col-span-6')}>
            <Typography as="h2" size="2xl">
              <Trans>Join this year's Christmas calender!</Trans>
            </Typography>
            <SanityContent blocks={simpBlock} />
            <div>
              <Button
                aria-label="register christmas calender"
                className="bg-red hover:bg-red-600 "
                onClick={() => {
                  const url =
                    router.locale === 'dk'
                      ? 'https://firi.julekalender.dk'
                      : 'https://firi.julekalender.no';
                  window.open(url, '_blank');
                }}
              >
                <Trans>Register now</Trans>
              </Button>
            </div>
          </GridItem>
        </Grid>
      </ContentWrapper>
    </section>
  );
};
