import ContentWrapper from 'components/ContentWrapper';
import { Grid, GridItem } from 'components/Grid';
import { Typography } from 'components/Typography';
import dynamic from 'next/dynamic';
import { CryptoCurrencySection as CryptoCurrencySectionType } from 'types/schema';

const CryptoCurrencyList = dynamic(() =>
  import('../CryptoCurrency/CryptoCurrencyList').then((mod) => mod.CryptoCurrencyList),
);
const FocusedCryptoCurrencyCards = dynamic(() =>
  import('../CryptoCurrency/FocusedCryptoCurrencyCards').then(
    (mod) => mod.FocusedCryptoCurrencyCards,
  ),
);

const CryptoCurrencyComponent = {
  list: CryptoCurrencyList,
  focused: FocusedCryptoCurrencyCards,
};

type Props = {
  node: CryptoCurrencySectionType;
};
export const CryptoCurrencySection = ({ node }: Props) => {
  const Component = node.visualization ? CryptoCurrencyComponent[node.visualization] : null;

  return (
    <section className="my-10 pb-10 border-b border-firi-blue">
      <ContentWrapper>
        <Grid className="mb-10">
          <GridItem className="md:col-span-8">
            <Typography size="xl" as="h3">
              {node.title}
            </Typography>
            <Typography className="mt-4" as="p">
              {node.text}
            </Typography>
          </GridItem>
        </Grid>
        {Component && <Component />}
      </ContentWrapper>
    </section>
  );
};
