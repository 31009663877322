import { Button } from 'components/Button';
import { CardWithTextAndImage } from 'components/CardWithTextAndImage';
import { useSanityLink } from 'hooks/useSanityLink';
import Link from 'next/link';
import { SectionCustom as SectionCustomType } from 'types/schema';

type Props = {
  node: SectionCustomType;
};

export const SectionCustom = ({ node }: Props) => {
  const { primaryCta, secondaryCta, showVippsCta, skin, title, body, svgType } = node;

  const primaryLinkProps = primaryCta?.href && useSanityLink(primaryCta?.href);
  const secondaryLinkProps = secondaryCta?.href && useSanityLink(secondaryCta?.href);

  return (
    <div className="mb-8">
      {svgType && skin && (
        <CardWithTextAndImage
          title={title}
          body={body}
          svgType={svgType}
          skin={skin}
          constrained
          showVippsCta={showVippsCta}
        >
          <div className="flex flex-col md:flex-row">
            {primaryCta && primaryCta.ctaText && primaryLinkProps && (
              <Link {...primaryLinkProps}>
                <a className="md:inline-block md:mr-4 mb-4 md:mb-0">
                  <Button skin="primary" full aria-label={primaryCta.ctaText} eventAction="CTA">
                    {primaryCta.ctaText}
                  </Button>
                </a>
              </Link>
            )}
            {secondaryCta && secondaryCta.ctaText && secondaryLinkProps && (
              <Link {...secondaryLinkProps}>
                <a className="md:inline-block md:mr-4 mb-4 md:mb-0">
                  <Button skin="outlined" full aria-label={secondaryCta.ctaText} eventAction="CTA">
                    {secondaryCta.ctaText}
                  </Button>
                </a>
              </Link>
            )}
          </div>
        </CardWithTextAndImage>
      )}
    </div>
  );
};
