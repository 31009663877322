import { firiMerge } from '@firi/shared/styles';
import { Card } from '@firi/ui-web';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { ReactNode } from 'react';
import React from 'react';
import { ReactChild, useState } from 'react';
import { SanityImageAssets } from 'types/sanity';

import { Button } from './Button';
import { SanityImage } from './SanityImage';
import { Typography } from './Typography';

type SectionProps = {
  title: string;
  _key: string;
  showToggle?: boolean;
  initialOpen?: boolean;
  subtitle?: ReactNode;
  highlighted?: boolean;
  constrained?: boolean;
  image?: SanityImageAssets;
};
export const Section: React.FC<SectionProps> = ({
  title,
  _key,
  children,
  showToggle,
  initialOpen = false,
  subtitle,
  highlighted,
  constrained,
  image,
}) => {
  const [expanded, setExpand] = useState(showToggle ? initialOpen : true);

  const handleExpand = () => setExpand(!expanded);
  return (
    <section
      id={_key}
      className={clsx(constrained && 'px-4 w-full lg:px-9 max-w-[1440px] mx-auto')}
    >
      <Card
        className={firiMerge('mb-8 p-6 lg:py-14 lg:px-20 lg:mb-10 overflow-hidden', {
          'rounded-none lg:rounded-xl': !highlighted,
          '!bg-blue-100': highlighted,
          '!p-0': image,
        })}
      >
        {image && (
          <div className="relative h-[240px] lg:h-[480px]">
            <SanityImage
              source={image}
              layout="fill"
              objectFit="cover"
              alt={title}
              sizes="(min-width: 36em) 40vw, 100vw"
            />
          </div>
        )}
        <div className={firiMerge({ 'p-6 lg:p-20': image })}>
          <div className={firiMerge(showToggle ? 'flex justify-between items-baseline' : '')}>
            <Typography as="h2" size="xl" color="blue">
              {title}
            </Typography>
            {showToggle && (
              <div className="flex-shrink-0 ml-4">
                <Button
                  skin="outlined"
                  aria-label={expanded ? t`Close section` : t`Show section`}
                  onClick={handleExpand}
                >
                  {expanded ? <Trans>Close section</Trans> : <Trans>Show section</Trans>}
                </Button>
              </div>
            )}
          </div>
          {!expanded && <div className="pt-4">{subtitle}</div>}
          <div
            className={clsx(
              'overflow-hidden transition-all',
              expanded ? 'max-h-[10000px] pt-4' : 'max-h-0',
            )}
          >
            {children as ReactChild}
          </div>
        </div>
      </Card>
    </section>
  );
};
